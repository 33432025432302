const local = {
  "client-port": 3000,
  "server-address": "http://192.168.1.8:1234",
};

const pre_prod = {
  "client-port": 5678,
  "server-address": "https://tomalab.com",
};

const config = function () {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return local;
    case "pre_prod":
      return pre_prod;
    default:
      return local;
  }
};

export default config();
