import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginUserAPI, logoutUserAPI, changePasswordAPI } from "../../api/userAPI";

import { setError } from "./errors";
import { clearData } from "./reports";

const auth = JSON.parse(sessionStorage.getItem("auth"));

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data, thunkAPI) => {
    try {
      const response = await loginUserAPI(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      thunkAPI.dispatch(setError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data, thunkAPI) => {
    try {
      const response = await changePasswordAPI(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      if (error.response.status === 403) {
        thunkAPI.dispatch(logoutUser());
        thunkAPI.dispatch(setError("Please Log In"));
        return thunkAPI.rejectWithValue();
      } else {
        // thunkAPI.dispatch(setError(message));
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  (value, thunkAPI) => {
    thunkAPI.dispatch(clearData());
    logoutUserAPI();
  }
);

const initialState = auth
  ? {
      user: auth.user,
      token: auth.token,
      isLoggedIn: true,
    }
  : {
      user: null,
      token: null,
      isLoggedIn: false,
    };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isLoggedIn = true;
      state.token = payload.token;
      state.user = payload.user;
    },
    [loginUser.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logoutUser.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
// export const {} = userSlice.actions;

export default userSlice.reducer;
