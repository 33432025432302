import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAnalysisListAPI,
  getHospitalListAPI,
  getReportFilesAPI,
  getReportListAPI,
} from "../../api/reportsAPI";

import moment from "moment";

import { setError } from "./errors";
import { logoutUser } from "./user";

const initialState = {
  reports: [],
  hospitals: [],
  analysis: [],
  isFetching: false,
  totalReports: null,
};

export const getReportList = createAsyncThunk(
  "reports/getReports",
  async (data, thunkAPI) => {
    Object.keys(data).forEach((item) => {
      if (data[item] === null) {
        delete data[item];
      }
    });
    let parsedData = {
      ...data,
      acceptanceStart:
        data.acceptanceStart &&
        moment(data.acceptanceStart).format("YYYY-MM-DD"),
      acceptanceEnd:
        data.acceptanceEnd && moment(data.acceptanceEnd).format("YYYY-MM-DD"),
      resultStart:
        data.resultStart && moment(data.resultStart).format("YYYY-MM-DD"),
      resultEnd: data.resultEnd && moment(data.resultEnd).format("YYYY-MM-DD"),
      hospitalId: data.hospitalId && data.hospitalId.id,
      analysisId: data.analysisId && data.analysisId.id,
    };
    try {
      const response = await getReportListAPI(parsedData);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      if (error.response.status === 403) {
        thunkAPI.dispatch(logoutUser());
        thunkAPI.dispatch(setError("Please Log In"));
        return thunkAPI.rejectWithValue();
      } else {
        thunkAPI.dispatch(setError(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getReportFiles = createAsyncThunk(
  "reports/getReportFiles",
  async (data, thunkAPI) => {
    try {
      const response = await getReportFilesAPI(data);
      let filename = response.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1]
        .replace(/"/g, "")
        .split(".")[0];
      return { name: filename, data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      thunkAPI.dispatch(setError(message));
      if (error.response.status === 403) {
        thunkAPI.dispatch(logoutUser());
        thunkAPI.dispatch(setError("Please Log In"));
        return thunkAPI.rejectWithValue();
      } else {
        thunkAPI.dispatch(setError(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getHospitalList = createAsyncThunk(
  "reports/getHospitals",
  async (_, thunkAPI) => {
    try {
      const response = await getHospitalListAPI();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      if (error.response.status === 403) {
        thunkAPI.dispatch(logoutUser());
        thunkAPI.dispatch(setError("Please Log In"));
        return thunkAPI.rejectWithValue();
      } else {
        thunkAPI.dispatch(setError(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getAnalysisList = createAsyncThunk(
  "reports/getAnalysis",
  async (_, thunkAPI) => {
    try {
      const response = await getAnalysisListAPI();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      if (error.response.status === 403) {
        thunkAPI.dispatch(logoutUser());
        thunkAPI.dispatch(setError("Please Log In"));
        return thunkAPI.rejectWithValue();
      } else {
        thunkAPI.dispatch(setError(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearData: (state) => {
      state.reports = [];
      state.hospitals = [];
      state.analysis = [];
      state.isFetching = false;
      state.totalReports = null;
    },
  },
  extraReducers: {
    [getReportList.fulfilled]: (state, action) => {
      state.reports = action.payload.data;
      state.total = action.payload.total;
      state.isFetching = false;
    },
    [getReportList.pending]: (state) => {
      state.isFetching = true;
    },
    [getReportList.rejected]: (state) => {
      state.reports = [];
      state.isFetching = false;
    },
    [getHospitalList.fulfilled]: (state, action) => {
      state.hospitals = action.payload.data;
    },
    [getHospitalList.rejected]: (state) => {
      state.hospitals = [];
    },
    [getAnalysisList.fulfilled]: (state, action) => {
      state.analysis = action.payload.data;
    },
    [getAnalysisList.rejected]: (state) => {
      state.analysis = [];
    },
  },
});

export const { clearData } = reports.actions;
export default reports.reducer;
