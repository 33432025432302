import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#359652",
    },
    secondary: {
      main: "#c445a5",
    },
    warning: {
        main: '#dbc72c'
    },
    background: {
        default: "#f7f7f7"
    }
  },
});

export default theme;
