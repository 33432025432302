import React from "react";
import { Pagination } from "@mui/material";

const CustomPagination = ({ count, rowsPerPage, page, onPageChange }) => {
  const numPages =
    count % rowsPerPage === 0
      ? Math.floor(count / rowsPerPage)
      : Math.floor(count / rowsPerPage) + 1;

  return (
    <>
      <Pagination
        count={numPages}
        page={page + 1}
        onChange={(e, value) => onPageChange(e, value - 1)}
        sx={{ minWidth: "fit-content", paddingLeft: "32px" }}
      />
    </>
  );
};

export default CustomPagination;
