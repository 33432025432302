import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { removeError } from "../redux/features/errors";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import itLocale from "date-fns/locale/it";
import {
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Box,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getReportList } from "../redux/features/reports";

const Form = ({ requestData, setrequestData, getReports }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { analysis, hospitals } = useSelector((state) => state.reports);
  const { error } = useSelector((state) => state.errors);

  const [mainfilter, setmainfilter] = useState("DISPONIBILI");
  const [analysisValue, setanalysisValue] = useState("");
  const [hospitalValue, sethospitalValue] = useState("");

  const handleChangeProgress = (e, newValue) => {
    e.preventDefault();
    let newData;
    if (newValue === null) {
      newValue = "TUTTI";
    }
    newData = {
      ...requestData,
      progress: newValue,
      page: 1,
    };
    setmainfilter(newValue);
    setrequestData(newData);
    dispatch(getReportList(newData));
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setrequestData({
      page: 1,
      itemsPerPage: 10,
      progress: "TUTTI",
      role: user.role,
      userId: user.userId,
      orderBy: "acceptanceDate",
      orderBy2: "desc",
    });
    setmainfilter("TUTTI");
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
        <Box sx={{ mt: 12 }}>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Benvenuto nell'area clienti Toma
          </Typography>
          {error !== "" && (
            <Alert
              onClose={() => {
                dispatch(removeError());
              }}
              severity="error"
              sx={{ mt: 2 }}
            >
              {error}
            </Alert>
          )}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Ricerca Avanzata</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="name"
                fullWidth
                label="Cognome e Nome"
                variant="outlined"
                sx={{ mb: 2 }}
                value={requestData.userTitle || ""}
                onChange={(e) =>
                  setrequestData({ ...requestData, userTitle: e.target.value })
                }
              />
              <TextField
                id="reportId"
                fullWidth
                label="Nº Referto"
                variant="outlined"
                sx={{ mb: 2 }}
                value={requestData.reportId || ""}
                onChange={(e) =>
                  setrequestData({ ...requestData, reportId: e.target.value })
                }
              />
              <Typography>Data accettazione</Typography>
              <FormGroup
                row
                sx={{
                  justifyContent: "space-between",
                  columnGap: "10px",
                  mb: 2,
                }}
              >
                <DatePicker
                  label="Da"
                  value={requestData.acceptanceStart || null}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  views={["year", "day"]}
                  clearable={true}
                  onChange={(newdate) =>
                    setrequestData({ ...requestData, acceptanceStart: newdate })
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ flexGrow: 1, mt: 1 }} />
                  )}
                />
                <DatePicker
                  label="A"
                  value={requestData.acceptanceEnd || null}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  views={["year", "day"]}
                  clearable={true}
                  onChange={(newdate) =>
                    setrequestData({ ...requestData, acceptanceEnd: newdate })
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ flexGrow: 1, mt: 1 }} />
                  )}
                />
              </FormGroup>

              <Typography>Data esito</Typography>
              <FormGroup
                row
                sx={{
                  justifyContent: "space-between",
                  columnGap: "10px",
                  mb: 2,
                }}
              >
                <DatePicker
                  label="Da"
                  value={requestData.resultStart || null}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  views={["year", "day"]}
                  clearable={true}
                  onChange={(newdate) =>
                    setrequestData({ ...requestData, resultStart: newdate })
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ flexGrow: 1, mt: 1 }} />
                  )}
                />
                <DatePicker
                  label="A"
                  value={requestData.resultEnd || null}
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  views={["year", "day"]}
                  clearable={true}
                  onChange={(newdate) =>
                    setrequestData({ ...requestData, resultEnd: newdate })
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ flexGrow: 1, mt: 1 }} />
                  )}
                />
              </FormGroup>

              {user.role === "S" && (
                <>
                  <Autocomplete
                    fullWidth
                    value={requestData.analysisId || null}
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      setrequestData({
                        ...requestData,
                        analysisId: newValue,
                      });
                    }}
                    inputValue={analysisValue}
                    onInputChange={(e, newInputValue) => {
                      setanalysisValue(newInputValue);
                    }}
                    getOptionLabel={(option) => option.description}
                    options={analysis}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(params, option) => (
                      <li {...params} key={params.id}>
                        {option.description}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Analisi" />
                    )}
                    sx={{ mb: 2 }}
                  />
                  <Autocomplete
                    fullWidth
                    value={requestData.hospitalId || null}
                    onChange={(e, newValue) => {
                      setrequestData({
                        ...requestData,
                        hospitalId: newValue,
                      });
                    }}
                    inputValue={hospitalValue}
                    onInputChange={(e, newInputValue) => {
                      sethospitalValue(newInputValue);
                    }}
                    getOptionLabel={(option) => option.description}
                    options={hospitals}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(params, option) => (
                      <li {...params} key={params.id}>
                        {option.description}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Ente" />
                    )}
                  />

                  <TextField
                    id="protocol"
                    fullWidth
                    label="Protocollo"
                    variant="outlined"
                    margin="normal"
                    value={requestData.protocol || ""}
                    onChange={(e) =>
                      setrequestData({
                        ...requestData,
                        protocol: e.target.value,
                      })
                    }
                  />
                </>
              )}
              <Button
                color="secondary"
                fullWidth
                variant="outlined"
                size="large"
                sx={{ mt: 1 }}
                onClick={resetFilters}
              >
                Reset Filtri
              </Button>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 1 }}
                onClick={(e) => getReports(e)}
              >
                Cerca
              </Button>
            </AccordionDetails>
          </Accordion>
          <ToggleButtonGroup
            color="primary"
            value={mainfilter}
            exclusive
            size="small"
            onChange={handleChangeProgress}
            sx={{ mt: 5, backgroundColor: theme.palette.common.white }}
          >
            <ToggleButton value="TUTTI">Tutti</ToggleButton>
            <ToggleButton value="DISPONIBILI">Disponibili</ToggleButton>
            {user.role !== "P" && (
              <ToggleButton value="PRELIMINARE">Preliminare</ToggleButton>
            )}
            <ToggleButton value="IN_ANALISI">In Analisi</ToggleButton>
            <ToggleButton value="NON_SCARICATO">Non scaricato</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default Form;
