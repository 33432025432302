import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
};

const errors = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    removeError: (state) => {
      state.error = "";
    },
  },
});

export const { setError, removeError, removeAll } = errors.actions;
export default errors.reducer;
