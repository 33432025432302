import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import reportReducer from "./features/reports";
import errorsReducer from "./features/errors";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["reports/getReportFiles/fulfilled"],
      },
    }),
  reducer: { user: userReducer, reports: reportReducer, errors: errorsReducer },
});
