import axios from "axios";
import config from "../config/config";

const apiURL = config["server-address"];

export const getReportListAPI = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/reports/get-report-list",
      data: data,
      headers: {
        "x-access-token": JSON.parse(sessionStorage.getItem("auth")).token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getReportFilesAPI = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/reports/get-report",
      data: data,
      headers: {
        "x-access-token": JSON.parse(sessionStorage.getItem("auth")).token,
      },
      responseType: 'blob'
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getHospitalListAPI = async () => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/reports/get-hospital-list",
      headers: {
        "x-access-token": JSON.parse(sessionStorage.getItem("auth")).token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnalysisListAPI = async () => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/reports/get-analysis-list",
      headers: {
        "x-access-token": JSON.parse(sessionStorage.getItem("auth")).token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
