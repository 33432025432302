import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { changePassword } from "../redux/features/user";

import {
  TextField,
  Backdrop,
  Box,
  Modal,
  Fade,
  Typography,
  Button,
  Stack,
  Alert,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ChangePassModal = ({ modalIsOpen, setmodalIsOpen }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState("");
  const [failure, setfailure] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    setsuccess("");
    setfailure("");
    const data = new FormData(e.currentTarget);
    if (data.get("new_pw") !== data.get("confirm_pw")) {
      setfailure("Passwords don't match");
      setloading(false);
    } else {
      let submitData = {
        username: user.username,
        password: data.get("old_pw"),
        newPassword: data.get("new_pw"),
      };
      dispatch(changePassword(submitData))
        .unwrap()
        .then(() => {
          setloading(false);
          setsuccess("Password Changed Successfully");
        })
        .catch((err) => {
          setloading(false);
          setfailure(err);
        });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setsuccess("");
    setfailure("");
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal
        open={modalIsOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalIsOpen}>
          <Box component="form" sx={style} onSubmit={handleSubmit}>
            <Typography variant="h6" component="h2">
              Cambia la tua password qui
            </Typography>

            {success !== "" && (
              <Alert severity="success" onClose={() => setsuccess("")}>
                {success}
              </Alert>
            )}

            {failure !== "" && (
              <Alert severity="error" onClose={() => setfailure("")}>
                {failure}
              </Alert>
            )}

            <TextField
              variant="outlined"
              color="primary"
              label="Vecchia Password"
              type="password"
              margin="normal"
              required
              id="old_pw"
              name="old_pw"
              fullWidth
            />
            <TextField
              variant="outlined"
              color="primary"
              label="Nuova Password"
              type="password"
              margin="normal"
              required
              id="new_pw"
              name="new_pw"
              fullWidth
            />
            <TextField
              variant="outlined"
              color="primary"
              label="Conferma Password"
              type="password"
              margin="normal"
              required
              id="confirm_pw"
              name="confirm_pw"
              fullWidth
            />
            <Stack direction="row" spacing={2} sx={{ mt: 2, width: "100%" }}>
              {loading ? (
                <>
                  <Button variant="outlined" fullWidth disabled>
                    Annulla
                  </Button>
                  <LoadingButton loading variant="contained" fullWidth>
                    Cambia
                  </LoadingButton>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => setmodalIsOpen(false)}
                  >
                    Annulla
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Cambia
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChangePassModal;
