import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";

import { getReportList, getReportFiles } from "../../redux/features/reports";

import EnhancedTableHead from "./TableHead";
import EnhancedTableToolbar from "./TableToolbar";
import CustomPagination from "./CustomPagination";

import { getRows, headCells } from "./tableData";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Badge,
  Button,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";

import { BsInfoSquare } from "react-icons/bs";

const EnhancedTable = ({ requestData, setrequestData }) => {
  const dispatch = useDispatch();

  const { reports, hospitals, total, isFetching } = useSelector(
    (state) => state.reports
  );

  const { user } = useSelector((state) => state.user);

  const rows = getRows(reports, hospitals);

  const [selected, setSelected] = useState([]);

  const page = requestData.page - 1;
  const setPage = (newPage) => {
    let newData = {
      ...requestData,
      page: newPage + 1,
    };
    dispatch(getReportList(newData));
    setrequestData(newData);
  };

  const rowsPerPage = requestData.itemsPerPage;
  const setRowsPerPage = (value) => {
    let newData = { ...requestData, itemsPerPage: value, page: 1 };
    dispatch(getReportList(newData));
    setrequestData(newData);
  };

  const handleRequestSort = (event, property) => {
    let isAsc;
    let order;
    if (property === "acceptanceDate" || property === "resultDate") {
      isAsc =
        requestData.orderBy === property && requestData.orderBy2 === "desc";
      order = isAsc ? "asc" : "desc";
    } else {
      isAsc =
        requestData.orderBy === property && requestData.orderBy2 === "asc";
      order = isAsc ? "desc" : "asc";
    }
    let newData = {
      ...requestData,
      page: 1,
      orderBy: property,
      orderBy2: order,
    };
    dispatch(getReportList(newData));
    setrequestData(newData);
  };

  const handleSelectAllClick = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      const newSelecteds = [];

      rows.forEach((n) => {
        if (!n.hasInfoIcon) newSelecteds.push(n.id);
      });

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const downloadPDF = (e, pdfId) => {
    e.preventDefault();
    dispatch(
      getReportFiles({
        format: "pdf",
        ids: [pdfId],
        role: user.role,
      })
    )
      .unwrap()
      .then(({ name, data }) => saveAs(data, `${name}.pdf`));
  };

  const downloadMany = (e) => {
    e.preventDefault();

    const pdfs = [];

    rows.forEach((entry) => {
      if (selected.includes(entry.id)) {
        entry.pdfId && pdfs.push(entry.pdfId);
      }
    });

    dispatch(
      getReportFiles({
        format: "zip",
        ids: pdfs,
        username: "",
        role: user.role,
      })
    )
      .unwrap()
      .then(({ name, data }) => saveAs(data, `${name}.zip`));
  };

  return (
    <>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            downloadMany={downloadMany}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={requestData.orderBy2}
                orderBy={requestData.orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.filter((x) => !x.hasInfoIcon).length}
              />
              {isFetching || rows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={headCells.length + 1} align="center">
                      {isFetching && <CircularProgress />}
                      {!isFetching && rows.length === 0 && (
                        <p>Nessun contenuto da mostrare</p>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    if (window.innerWidth > 800) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              disabled={row.hasInfoIcon}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row[headCells[0].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[1].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[2].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[3].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[4].id]}
                          </TableCell>
                          <TableCell align="center">
                            {row.hasInfoIcon ? (
                              <>
                                <Tooltip
                                  placement="top"
                                  title="Il referto non è più disponible sul sito. E’ possible richiederlo scrivendo a segreteria@tomalab.com"
                                >
                                  <IconButton color="info">
                                    <BsInfoSquare />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              row[headCells[5].id] && (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={(e) =>
                                    downloadPDF(e, row[headCells[5].id])
                                  }
                                >
                                  PDF
                                </Button>
                              )
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Chip
                              size="small"
                              color={row[headCells[6].id]}
                              label={
                                row[headCells[6].id] === "success"
                                  ? "Disponibili"
                                  : row[headCells[6].id] === "warning"
                                  ? "Preliminari"
                                  : row[headCells[6].id] === "error"
                                  ? "In Analisi"
                                  : row[headCells[6].id] === "info"
                                  ? "Già scaricato"
                                  : ""
                              }
                            />
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[7].id]}
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row[headCells[0].id]}
                          </TableCell>
                          <TableCell align="left">
                            <Badge
                              color={row[headCells[1].id]}
                              badgeContent=""
                              sx={{ ml: 2 }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.hasInfoIcon ? (
                              <>
                                <Tooltip
                                  placement="top"
                                  title="Il referto non è più disponible sul sito. E’ possible richiederlo scrivendo a segreteria@tomalab.com"
                                >
                                  <IconButton color="info">
                                    <BsInfoSquare />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              row[headCells[2].id] && (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={(e) =>
                                    downloadPDF(e, row[headCells[2].id])
                                  }
                                >
                                  PDF
                                </Button>
                              )
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[3].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[4].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[5].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[6].id]}
                          </TableCell>
                          <TableCell align="left">
                            {row[headCells[7].id]}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {rows.length !== 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Referti per pagina"
              ActionsComponent={CustomPagination}
            />
          )}
        </Paper>
      </Box>
    </>
  );
};
export default EnhancedTable;
