export const headCells = (() => {
  if (window.innerWidth > 800) {
    return [
      {
        id: "reportId",
        numeric: false,
        disablePadding: true,
        label: "N° Referto",
      },
      {
        id: "acceptanceDate",
        numeric: false,
        disablePadding: false,
        label: "Data acc.ne",
      },
      {
        id: "resultDate",
        numeric: false,
        disablePadding: false,
        label: "Data esito",
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Nome",
      },
      {
        id: "entity",
        numeric: false,
        disablePadding: false,
        label: "Ente",
      },
      {
        id: "pdfId",
        numeric: false,
        disablePadding: false,
        label: "Vedi referto",
      },
      {
        id: "state",
        numeric: false,
        disablePadding: false,
        label: "Stato",
      },
      {
        id: "note",
        numeric: false,
        disablePadding: false,
        label: "Note",
      },
    ];
  } else {
    return [
      {
        id: "reportId",
        numeric: false,
        disablePadding: true,
        label: "N° Referto",
      },
      {
        id: "state",
        numeric: false,
        disablePadding: false,
        label: "Stato",
      },
      {
        id: "pdfId",
        numeric: false,
        disablePadding: false,
        label: "Vedi referto",
      },
      {
        id: "note",
        numeric: false,
        disablePadding: false,
        label: "Note",
      },
      {
        id: "acceptanceDate",
        numeric: false,
        disablePadding: false,
        label: "Data acc.ne",
      },
      {
        id: "resultDate",
        numeric: false,
        disablePadding: false,
        label: "Data esito",
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Nome",
      },
      {
        id: "entity",
        numeric: false,
        disablePadding: false,
        label: "Ente",
      },
    ];
  }
})();

export const getRows = (reports, hospitals) => {
  const rows = [];
  reports.map((report) => {
    let row = {
      id: report.id,
      hasInfoIcon: false,
    };
    headCells.forEach((column) => {
      if (report[`${column.id}`] === null) return;
      else if (column.id === "reportId") {
        row[column.id] = report[`directory`] + report["protocol"];
      } else if (column.id === "acceptanceDate" || column.id === "resultDate") {
        let date = new Date(report[`${column.id}`]);
        let options = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        row[column.id] = date.toLocaleDateString("it-IT", options);
      } else if (column.id === "entity") {
        row[column.id] = hospitals.map((value) => {
          return value.id === report["idEntity"] && value.description;
        });
      } else if (column.id === "state") {
        switch (report.state) {
          case "NON_SCARICATO":
            row[column.id] = "success";
            break;
          case "PRELIMINARE":
            row[column.id] = "warning";
            break;
          case "IN_ANALISI":
            row[column.id] = "error";
            break;
          case "SCARICATO":
            row[column.id] = "info";
            break;
          case "GREATER_THAN_X_DAYS":
            row[column.id] = "success";
            row.hasInfoIcon = true;
            break;
          default:
            break;
        }
      } else {
        row[column.id] = report[`${column.id}`];
      }
    });
    rows.push(row);
    return rows;
  });
  return rows;
};
