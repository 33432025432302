import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "../redux/features/user";

import Logo from "../assets/img/Logo-Header.png";

import {
  Box,
  AppBar,
  Button,
  ButtonGroup,
  Container,
  Toolbar,
} from "@mui/material";
import ChangePassModal from "./ChangePassModal";

const Appbar = ({ modalIsOpen, setmodalIsOpen }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="background">
          <Container maxWidth="lg">
            <Toolbar
              disableGutters
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ maxHeight: "2em" }} component="img" src={Logo} />
              <ButtonGroup variant="text">
                {user.role !== "P" && (
                  <Button color="primary" onClick={() => setmodalIsOpen(true)}>
                    Cambia Password
                  </Button>
                )}
                <Button color="primary" onClick={onLogout}>
                  Esci
                </Button>
              </ButtonGroup>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <ChangePassModal
        modalIsOpen={modalIsOpen}
        setmodalIsOpen={setmodalIsOpen}
      />
    </>
  );
};

export default Appbar;
