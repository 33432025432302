import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { loginUser } from "../redux/features/user";
import { removeError } from "../redux/features/errors";

import Logo from "../assets/img/Logo-Header.png";

import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.errors);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    let submitData = {
      username: data.get("username"),
      password: data.get("password"),
    };
    dispatch(loginUser(submitData));
  };

  if (isLoggedIn) {
    return <Redirect to="/dash" />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "grid",
          placeItems: "center",
          minHeight: "90vh",
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={Logo}
            sx={{ maxHeight: "2.7rem", marginBottom: 3 }}
          ></Box>
          <Typography variant="h5" align="center">
            Benvenuto nell'area clienti Toma, Accedi per consultare i tuoi
            referti
          </Typography>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginY: 5
            }}
            maxWidth="xs"
          >
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
              {error !== "" && (
                <Alert
                  onClose={() => {
                    dispatch(removeError());
                  }}
                  severity="error"
                  sx={{ mt: 1 }}
                >
                  {error}
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Codice Utente"
                name="username"
                autoFocus
                sx={{ backgroundColor: theme.palette.common.white }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ backgroundColor: theme.palette.common.white }}
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
};

export default Login;
