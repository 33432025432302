import React from "react";
import PropTypes from "prop-types";

import { alpha } from "@mui/material/styles";

import { Toolbar, Typography, Button } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";

const EnhancedTableToolbar = (props) => {
  const { numSelected, downloadMany } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Referti
        </Typography>
      )}

      {numSelected > 0 && (
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={downloadMany}
        >
          Download
        </Button>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
