import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import {
  getAnalysisList,
  getHospitalList,
  getReportList,
} from "../redux/features/reports";
import { removeError } from "../redux/features/errors";

import Form from "../components/Form";
import Table from "../components/Table/Table";
import Appbar from "../components/Appbar";

import { Container } from "@mui/material";

const Admin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isLoggedIn, user } = useSelector((state) => state.user);

  const [requestData, setrequestData] = useState({
    page: 1,
    itemsPerPage: 10,
    progress: "DISPONIBILI",
    orderBy: "acceptanceDate",
    orderBy2: "desc",
  });

  const [modalIsOpen, setmodalIsOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAnalysisList()).then(() => {
        dispatch(getHospitalList());
      });
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    dispatch(removeError());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(
        getReportList({
          ...requestData,
          role: user.role,
          userId: user.userId,
        })
      );
      setrequestData({
        ...requestData,
        role: user.role,
        userId: user.userId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getReports = () => {
    setrequestData({ ...requestData, page: 1 });
    dispatch(getReportList({ ...requestData, page: 1 }));
  };

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Appbar modalIsOpen={modalIsOpen} setmodalIsOpen={setmodalIsOpen} />
      <Container
        maxWidth="lg"
        sx={{ mb: "4rem", backgroundColor: theme.background }}
      >
        <Form
          getReports={getReports}
          requestData={requestData}
          setrequestData={setrequestData}
        />
        <Table
          requestData={requestData}
          setrequestData={setrequestData}
          getReports={getReports}
        />
      </Container>
    </>
  );
};

export default Admin;
