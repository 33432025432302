import axios from "axios";
import config from "../config/config";

const apiURL = config["server-address"];

export const loginUserAPI = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/users/login",
      data: data,
    });
    sessionStorage.setItem(
      "auth",
      JSON.stringify({ token: response.data.token, user: response.data.user })
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changePasswordAPI = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: apiURL + "/users/change-password",
      data: data,
      headers: {
        "x-access-token": JSON.parse(sessionStorage.getItem("auth")).token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutUserAPI = () => {
  sessionStorage.removeItem("auth");
};
